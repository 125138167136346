<template lang="pug">
.content-wrapper
  .content-header.d-flex.flex-column.flex-md-row
    h4.mb-0
      | {{ $t('admin.chatGPTClassifications.new.title') }}
  .row.flex-grow
    .col-12
      .card
        .card-body
          form
            .alert.alert-danger(v-if="errorMessages.length > 0")
              ul.mb-0
                li(v-for="error in errorMessages")
                  | {{ error }}

            validation-observer(v-slot="{ invalid }")
              validation-provider(name="surveyId" rules="required|numeric" v-slot="{ errors }")
                .form-group(:class="{ 'has-danger': errors.length}")
                  label
                    | SurveyId
                  input(
                    type="text"
                    :class="{ 'form-control': true, 'form-control-danger': errors.length }"
                    v-model="survey_id"
                  )
                  label.error.mt-2.text-danger(v-show="errors.length")
                    | {{ errors[0] }}

              validation-provider(name="questionId" rules="required|numeric" v-slot="{ errors }")
                .form-group(:class="{ 'has-danger': errors.length}")
                  label
                    | QuestionId
                  input(
                    type="text"
                    :class="{ 'form-control': true, 'form-control-danger': errors.length }"
                    v-model="question_id"
                  )
                  label.error.mt-2.text-danger(v-show="errors.length")
                    | {{ errors[0] }}

              validation-provider(name="labelsNum" rules="required|numeric" v-slot="{ errors }")
                .form-group(:class="{ 'has-danger': errors.length}")
                  label
                    | Number of clusters
                  input(
                    type="text"
                    :class="{ 'form-control': true, 'form-control-danger': errors.length }"
                    v-model="labels_num"
                  )
                  label.error.mt-2.text-danger(v-show="errors.length")
                    | {{ errors[0] }}

              validation-provider(name="extract_type" rules="required" v-slot="{ errors }")
                .form-group(:class="{ 'has-danger': errors.length}")
                  label
                    | ExtractType (※サンプル数が少ない場合は、shortを選択してください)
                  select(
                    :class="{ 'form-control': true, 'form-control-danger': errors.length }"
                    v-model="extract_type"
                  )
                    option(
                      v-for="et in extract_types"
                      v-bind:value="et.id"
                      v-bind:key="et.id"
                    )
                      | {{et.name}}
                  label.error.mt-2.text-danger(v-show="errors.length")
                    | {{ errors[0] }}

              //- チェックボックスを追加
              .form-group
                .custom-control.custom-checkbox.vc-custom-checkbox
                  input.custom-control-input(
                    type="checkbox"
                    id="require-upload"
                    v-model="requireUpload"
                  )
                  label.col-form-label.custom-control-label(for="require-upload")
                    | 回答データをアップロードする(※手動で配置する場合はチェックを外す)

              .mt-5.float-right
                button.btn.btn-success(
                  :disabled="processing || invalid"
                  @click.prevent="submit"
                )
                  | クラスタリング実行

</template>

<script>
import { mapActions } from 'vuex';

export default {
  components: {},
  data() {
    return {
      survey_id: '',
      question_id: '',
      labels_num: 10,
      requireUpload: true,
      errorMessages: [],
      processing: false,
      extract_type: 'normal',
      extract_types: [
        { id: 'normal', name: 'Normal' },
        { id: 'short', name: 'Short' }
      ]
    };
  },
  computed: {
    /**
     * リクエストパラメータ
     * @return {Object}
     */
    requestParams() {
      return {
        survey_id: this.survey_id,
        question_id: this.question_id,
        labels_num: this.labels_num,
        require_upload: this.requireUpload,
        extract_type: this.extract_type
      };
    }
  },
  created() {},
  mounted() {},
  methods: {
    ...mapActions('resources/x_chatgpt_classifications', ['requestClustering']),

    /**
     * エラーメッセージをリセット
     */
    resetErrorMessages() {
      this.errorMessages = [];
    },

    /**
     * リセットパラメータ
     */
    resetParams() {
      this.survey_id = '';
      this.question_id = '';
      this.labels_num = 10;
      this.requireUpload = true;
      this.extract_type = 'normal';
    },

    /**
     * フォーム送信
     */
    submit() {
      this.processing = true;
      this.resetErrorMessages();
      this.requestClustering(this.requestParams)
        .then(() => {
          this.resetParams();
          this.$router.push({ name: 'x_chatgpt_classifications' });
        })
        .catch(error => {
          this.errorMessages = error.response.data.errors;
        })
        .finally(() => {
          this.processing = false;
        });
    }
  }
};
</script>

<style scoped></style>
